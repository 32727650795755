import debounce from "lodash.debounce";

export default {
  data() {
    return {
      items: [],
      dataMethod: "getPaginated",
      total: null,
      options: {
        itemsPerPage: 15,
        params: {}
      },
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Status", value: "is_active" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    defaultAttrs: {
      get() {
        return {
          items: this.items,
          headers: this.headers,
          loading: this.loading,
          "footer-props": { "items-per-page-options": [5, 15, 30, 50, 100] },
          "server-items-length": this.total
        };
      }
    }
  },
  mounted() {
    this.getPaginatedProxy();
  },
  methods: {
    getPaginated() {
      return new Promise(resolve => {
        console.error(
          `Set up the ${this.dataMethod}() method, or configure the dataMethod property`
        );
        resolve({});
      });
    },
    getPaginatedProxy() {
      this.loading = true;
      this[this.dataMethod](this.options).then(({ data }) => {
        if (data?.success === true) {
          this.items = data.data;
          this.total = data.pagination.count;
        } else {
          console.error("data.success wasn't true", data);
        }
        this.loading = false;
      });
    },
    getPaginatedDebounced: debounce(function() {
      this.getPaginatedProxy();
    }, 200),
    refresh() {
      this.getPaginatedDebounced();
    }
  },
  watch: {
    options: {
      handler() {
        this.getPaginatedDebounced();
      },
      deep: true
    }
  }
};
